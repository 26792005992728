import gql from 'graphql-tag';

import ImageCarouselFragment from '../fragments/ImageCarouselFragment';
import colorFragment from '../fragments/ColorFragment';
import displayFlagsFragment from '../fragments/DisplayFlagsFragment';
import cmsTextBlock from '../fragments/TextBlockFragment';
import marketingBannerFragment from '../fragments/BannerFragment';
import ImageButtonsFragment from '../fragments/ImageButtonsFragment';
import citrusBannerImageSlotFragment from '../fragments/CitrusBannerImageSlotFragment';

import {storeAdFragment} from './ads-queries';

export const cmsSimpleButton = gql`
fragment ISimpleButton on CmsSimpleButton {
  buttonText
  href
  type
}
`;

export const cmsTextContentFragment = gql`
  ${cmsTextBlock}

  fragment ITextContent on CmsTextContent {
    body {
      ...ITextBlock
    }
    title
  }
`;

export const cmsImageWithAlt = gql`
  fragment IImageWithAlt on CmsImageWithAlt {
      alt
      asset {
        _id
        assetId
        description
        extension
        label
        mimeType
        originalFilename
        path
        sha1hash
        size
        source {
          id
          name
          url
        }
        title
        url
      }
    }
`;

export const cmsImageWithContentFragment = gql`
  ${cmsTextContentFragment}
  ${cmsImageWithAlt}

  fragment IImageWithContent on CmsImageWithContent {
    title
    image {
      ...IImageWithAlt
    }
    textContent {
      ...ITextContent
    }
    linkUrl
    linkText
    justifyContent
    backgroundColor {
      hex
    }
  }
`;

export const cmsProgressBarFragment = gql`
  ${colorFragment}

  fragment IProgressBar on CmsMarketingDealsDonationPageProgressBar {
    _type
    donateUrl
    color {
      ...IColor
    }
    currentDollar
    endDate
    goalDollar
    hyveeDollarDonation
    backgroundColor {
      ...IColor
    }

  }
`;

export const cmsAdIcon = gql`
  fragment IAdIcon on CmsMarketingDealsAdIcon {
      image {
          asset {
              title
              url
          }
      }
      title
      subtext
      adTitle
      filterQueryParams
      overrideUrl
      isWeeklyAd
  }
`;

export const cmsAdIconsSection = gql`
  ${cmsAdIcon}
  ${storeAdFragment}
  ${displayFlagsFragment}

  fragment IAdIconsSection on CmsMarketingDealsAdIconsSection {
    _type
    _key
    adIcons {
      ...IAdIcon
    }
    title
    largeTitleToggle
    subtext
    backgroundColor {
      hex
    }
    storeAds(storeId: $storeId) {
      ...IStoreAd
    }
    displayFlags {
      ...IDisplayFlags
    }
  }
`;

export const cmsSliderBarContent = gql`
  fragment ISlider on CmsMarketingDealsDonationPageSlider {
    color {
      hex
    }
    defaultBreakpoint
    donateButton {
      buttonText
      href
      disabled
    }
    breakpoints
  }
`;

export const cmsVerticalCardFragment = gql`
  ${cmsSimpleButton}
  ${displayFlagsFragment}
  
  fragment IVerticalCard on CmsVerticalCard {
    _type
    _key
    tacticId
    bodyText: bodyTextRaw
    image {
      alt
      asset {
        url
      }
    }
    videoUrl
    buttons {
      ...ISimpleButton
    }
    imageLinkUrl
    bodyTextLinkUrl
    displayFlags {
      ...IDisplayFlags
    }
    backgroundColor {
        hex
    }
  }
`;

export const cmsHorizontalCardFragment = gql`
  ${cmsSimpleButton}
  
  fragment IHorizontalCard on CmsHorizontalCard {
    _type
    _key
    bodyText: bodyTextRaw
    image {
      alt
      asset {
        url
      }
    }
    headerText
    videoUrl          
    imageSide
    buttons {
      ...ISimpleButton
    }
    imageLinkUrl
    isExtraWide  
    bodyTextLinkUrl
    backgroundColor {
        hex
    }
  }
`;

export const cmsHeroBanner = gql`
    ${displayFlagsFragment}
    fragment IHeroBanner on CmsHeroBanner {
        _type
        _key
        backgroundImage {
            alt
            asset {
                url
            }
        }
        tabletBackgroundImage {
            alt
            asset {
                url
            }
        }
        mobileBackgroundImage {
            alt
            asset {
                url
            }
        }
        buttons {
            buttonLink
            buttonText
        }
        header
        backgroundVideoAlt
        backgroundVideoUrl
        mediaHeight
        mediaWidth
        subheader
        contentSide
        displayFlags {
            ...IDisplayFlags
        }
    }
`;

export const cmsDecoration = gql`
    fragment IDecoration on CmsDecoration {
        _type
        _key
        decorationName
        decorationBackgroundColor {
            hex
        }
        decorationColorAccent {
            hex
        }
        decorationColorBackground {
            hex
        }
        height
        width
    }
`;

export const cmsCardCollection = gql`
    ${cmsVerticalCardFragment}
    ${cmsHorizontalCardFragment}
    ${displayFlagsFragment}
    
    fragment ICardCollection on CmsCardCollection {
        _type
        _key
        maxMobileCards
        textAlignment
        textColor {
            hex
        }
        backgroundColor {
            hex
        }
        cards {
            ... IVerticalCard
            ... IHorizontalCard
        }
        displayFlags {
            ...IDisplayFlags
        }
    }
`;

export const cmsImageCardsWithLinks = gql`
    fragment IImageCardsWithLinks on CmsImageCardsWithLinks {
        _type
        cards {
            _type
            link
            image {
                alt
                asset {
                    url
                }
            }
        }
    }    
`;

export const cmsCouponCategory = gql`
    fragment ICouponCategory on CmsCouponCategory {
        _type
        category
        tacticId
        cards {
            __typename
            couponId
            offerState
            imageUrl
            description
            clipEndDate
            valueText
        }
    }
`;

export const cmsDealsByAdId = gql`
    fragment IDealsByAdId on CmsDealsByAdId {
        _type
        adId
        cards(storeId: $storeId, fuelSaverCardNumber: $fuelSaverCardNumber) {
            __typename
            dealId
            price
            priceMultiple
            beginDate
            endDate
            deal {
                dealId
                dealTitle
                dealDescription
                imageUri
                varietiesCount
                fuelSaverDescription
                size
            }
        }
    }
`;

export const cmsCardSwimlane = gql`    
    ${cmsImageCardsWithLinks}
    ${cmsCouponCategory}
    
    fragment ICardSwimlane on CmsCardSwimlane {
        _id
        _key
        _type
        icon {
            alt
            asset {
                url
            }
        }
        seeAllLink
        subtitle
        title
        backgroundColor {
            hex
        }
        cardType {
            ...IImageCardsWithLinks
            ...ICouponCategory
        }
    }
`;

export const cmsMarketingDealsDonationBannerFragment = gql`
  ${colorFragment}
  ${cmsProgressBarFragment}
  ${cmsImageWithAlt}

  fragment ICmsMarketingDealsDonationBanner on CmsMarketingDealsDonationBanner {
    _type
    __typename
    _key
    backgroundColor {
      ...IColor
    },
    secondaryBackgroundColor {
      ...IColor
    },
    href
    desktopImage{
      ...IImageWithAlt
    }
    tabletImage{
      ...IImageWithAlt
    }
    mobileImage{
      ...IImageWithAlt
    }
    progressBar {
      ...IProgressBar
    }
  }
`;

export const sanityImageAssetFragment = gql`
  fragment ISanityImageAsset on SanityImageAsset {
    _id
    assetId
    description
    extension
    label
    mimeType
    originalFilename
    path
    sha1hash
    size
    source {
      id
      name
      url
    }
    title
    url
  }
`;

export const cmsAdBannerFragment = gql`
  ${sanityImageAssetFragment}
  fragment ICmsBannerImage on CmsBannerImage {
    alt
    asset {
      ...ISanityImageAsset
    }
    colSpan
  }
`;

export const cmsAdSectionFragment = gql`
  ${cmsAdBannerFragment}
  ${colorFragment}
  ${displayFlagsFragment}

  fragment ICmsAdSection on CmsAdSection {
    _type
    _key
    banners {
      title
      desktopImage {
        ...ICmsBannerImage
      }
      tabletImage { 
        ...ICmsBannerImage
      }
      mobileImage {
        ...ICmsBannerImage
      }
      href
      __typename
    }
    backgroundColor {
      ...IColor
    }
    _type
    displayFlags {
      ...IDisplayFlags
    }
  }
`;

const cmsCardSwimlaneFragment = gql`
    ${displayFlagsFragment}

 fragment ICmsCardSwimlane on CmsCardSwimlane {
    _type
    _key
    icon {
      alt
      asset {
        url
      }
    }
    iconColor {
      hex
    }
    seeAllLink
    seeAllLinkColor {
      hex
    }
    title
    largeTitleToggle
    textColor {
      hex
    }
    backgroundColor {
      hex
    }
    backgroundTile {
        alt
        asset {
            url
        }
    } 
    cardType {
      ... on CmsImageCardsWithLinks {
          _type
          cards {
            _type
            link
            image {
                alt
                asset {
                  url
                }
            }
          }
      }

      ... on CmsCouponCategory {
        _type
        category
        tacticId
        cards {
          __typename
          couponId
          offerState
          imageUrl
          description
          clipEndDate
          valueText,
          upcs
        }
      }

      ... on CmsDealsByAdId {
        _type
        adId
        cards(storeId: $storeId, fuelSaverCardNumber: $fuelSaverCardNumber) {
          __typename
          dealId
          price
          priceMultiple
          beginDate
          endDate
          deal {
            dealId
            dealTitle
            dealDescription
            imageUri
            varietiesCount
            fuelSaverDescription
            size
          }
        }
      }
        
      ... on CmsNationalOffersCollection {
        _type 
        category
        cards {
            __typename
            cashBackOfferId
            category
            offerStartDate
            offerEndDate
            offerState
            brand
            value
            valueText
            imageUrl
            description
        }  
      }  

      ... on CmsStoreAdAndLocalAdCards {
        _type
        shouldShowLocalAd
        cards(storeId: $storeId) {
          ... on storeAd {
            __typename
            corpId
            storeAdId
            storeId
            imageUri
            beginDate
            endDate
            ad {
              adId
              description
            }
          }
          ... on localAd {
            __typename
            localAdId
            title
            startDate
            endDate
            imageUri
            pngFilePath
            pdfFolderName
          }
        }
      }
        
      ... on CmsStoreProductCollection {
        fuelSaverProducts
        _type
        cards {
            __typename
            storeProductId
            isWeighted
            onSale
            onFuelSaver
            fuelSaver
            storeProductDescriptions {
                description
                type
            }
            product {
                averageWeight
                productId
                name
                productImages {
                    size
                    isPrimary
                    uri
                    type
                    viewType
                }
                size
                couponProductV4  {
                    upc
                    couponsV4  {
                        couponId
                        brand
                        offerState
                        valueText
                    }
                }
                item {
                    unitAverageWeight
                    retailItems (locationIds: $locationIds) {
                        basePrice
                        soldByUnitOfMeasure {
                            code
                        }
                        tagPrice
                        tagPriceQuantity
                        ecommerceTagPrice
                        ecommerceTagPriceQuantity
                        memberTagPrice @include(if: $perksPricingEnabled)
                    }
                }
            }
        } 
      }    
        
      ... on  CmsProductGroupCollection {
          _type
          productGroupId
          cards {
              __typename
              storeProductId
              isWeighted
              onSale
              onFuelSaver
              fuelSaver
              storeProductDescriptions {
                  description
                  type
              }
              product {
                  averageWeight
                  productId
                  name
                  productImages {
                      size
                      isPrimary
                      uri
                      type
                      viewType
                  }
                  size
                  couponProductV4  {
                      upc
                      couponsV4  {
                          couponId
                          brand
                          offerState
                          valueText
                      }
                  }
                  item {
                      unitAverageWeight
                      retailItems (locationIds: $locationIds) {
                          basePrice
                          soldByUnitOfMeasure {
                              code
                          }
                          tagPrice
                          tagPriceQuantity
                          ecommerceTagPrice
                          ecommerceTagPriceQuantity
                          memberTagPrice @include(if: $perksPricingEnabled)
                      }
                  }
              }    
          }
      }
    }
    displayFlags {
      ...IDisplayFlags
    }
  }
`;

const superHotDealsBannerFragment = gql`
    ${displayFlagsFragment}
    
 fragment ICmsSuperHotDealsBanner on CmsSuperHotDealsBanner {
    _key
    __typename
    _type
    name
    webImage {
      alt
      asset {
        url
        metadata {
          dimensions {
            height
            width
          }
        }
      }
    }
    tabletImage {
      alt
      asset {
        url
        metadata {
          dimensions {
            height
            width
          }
        }
      }
    }
    mobileImage {
      alt
      asset {
        url
        metadata {
          dimensions {
            height
            width
          }
        }
      }
    }
    bgColor{
      hex
    }
    secondaryBgColor {
        hex
    }
    href
    displayFlags {
      ...IDisplayFlags
    }
  }
`;

const superHotDealsFragment = gql`
    ${displayFlagsFragment}
    
  fragment ICmsSuperHotDeals on CmsSuperHotDeals {
    _type
    _key
    __typename
    largeTitleToggle
    descriptionRaw
    descFontColor {
      hex
    }
    bgColor {
      hex
    }
    bgOverlayImage {
      asset {
        url
      }
    }
    bgImage {
      asset {
        url
      }
    }
    displayFlags {
      ...IDisplayFlags
    }
    deals {
      __typename
      _key
      _type
      title
      couponId,
      coupon {
        offerState
      }
      price
      size
      luNumber
      disclaimer
      badge {
        bgColor {
          hex  
        }
        text
        textColor {
          hex  
        }  
        borderColor {
          hex 
        } 
        icon {
          alt
          asset {
            url  
          }  
        }  
      }  
      image {
        alt
        asset {
          url
        }
      }
    }
  }
`;

export const getCmsMarketingDealsDonationPage = gql`
  ${cmsImageWithContentFragment}
  ${cmsProgressBarFragment}
  ${cmsTextContentFragment}
  ${cmsVerticalCardFragment}
  ${cmsSliderBarContent}
  ${cmsAdIconsSection}
  ${cmsCardCollection}

  query GetCmsMarketingDealsDonationPage($storeId: Int!) {
    allCmsMarketingDealsDonationPage {
      title
      publishedAt
      endAt
      imageTextContent {
        ...IImageWithContent
      }
      slider {
        ...ISlider
      }
      progressBarContent {
        progressBar {
          ...IProgressBar
        }
        textContent {
          ...ITextContent
        }
      }
      cardCollection {
        ...ICardCollection
      }
      banner {
        mediaHeight
        mediaWidth
        backgroundImage {
          asset {
            url
          }
          alt
        }
        tabletBackgroundImage {
          asset {
            url
          }
          alt
        }
        mobileBackgroundImage {
          asset {
            url
          }
          alt
        }
      }
      adsIconsSection {
        ...IAdIconsSection
      }  
    }
  }
`;

export const getPrintableCoupons = gql`
  query getPrintableCoupons {
      allCmsDealsHomePage(
        limit: 1
        sort: { publishedAt: DESC}
        where: { _: {is_draft:false}}) {
        body {
          ... on CmsSuperHotDeals {
            deals {
              image {
                alt
                asset {
                  metadata {
                    dimensions {
                      height
                      width
                    }
                  }
                  url
                }
              }
              couponId
              title
              price
              size
              luNumber
              disclaimer
              badge {
                bgColor {
                  hex
                }
                text
                textColor {
                  hex
                }
                borderColor {
                  hex
                }
                icon {
                  alt
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
`;

const dailyDealFragment = gql`
    ${superHotDealsBannerFragment}
    ${displayFlagsFragment}
    
    fragment ICmsDailyDeal on CmsMarketingDealsDailyDeal {
        _type
        title
        productId
        heroBanner {
            ...ICmsSuperHotDealsBanner
        }
        displayFlags {
            ...IDisplayFlags
        }
    }
`;

const dailyDealConfigFragment = gql`
    ${dailyDealFragment}
    ${superHotDealsBannerFragment}
    
    fragment ICmsDailyDealsConfig on CmsMarketingDealsDailyDealWeeklyConfig {
        _type
        _key
        __typename
        configurationTitle
        sunday {
            banner {
              ...ICmsDailyDeal
              ...ICmsSuperHotDealsBanner
            }
        }
        monday {
          banner {
            ...ICmsDailyDeal
            ...ICmsSuperHotDealsBanner
          }
        }
        tuesday {
          banner {
            ...ICmsDailyDeal
            ...ICmsSuperHotDealsBanner
          }
        }
        wednesday {
          banner {
            ...ICmsDailyDeal
            ...ICmsSuperHotDealsBanner
          }
        }
        thursday {
          banner {
            ...ICmsDailyDeal
            ...ICmsSuperHotDealsBanner
          }
        }
        friday {
          banner {
            ...ICmsDailyDeal
            ...ICmsSuperHotDealsBanner
          }
        }
        saturday {
          banner {
            ...ICmsDailyDeal
            ...ICmsSuperHotDealsBanner
          }
        }
    }
`;

export const getCmsDealsHomePage = gql`    
  ${cmsHeroBanner}  
  ${cmsMarketingDealsDonationBannerFragment}
  ${cmsAdIconsSection}
  ${cmsCardCollection}
  ${cmsDecoration}
  ${cmsAdSectionFragment}
  ${cmsTextBlock}
  ${cmsCardSwimlaneFragment}
  ${superHotDealsBannerFragment}
  ${superHotDealsFragment}
  ${dailyDealConfigFragment}
  ${marketingBannerFragment}
  ${ImageCarouselFragment}
  ${ImageButtonsFragment}
  ${citrusBannerImageSlotFragment}

  query getDealsHomePage(
    $storeId: Int!
    $fuelSaverCardNumber: String
    $locationIds: [ID!]!
    $perksPricingEnabled: Boolean = false
  ) {
  CmsDealsHomePage: allCmsDealsHomePage(
            limit: 1
            sort: { publishedAt: DESC }
            where:{
                _: {is_draft:false}
            }){
    _type
    _key
    body {
      ...IHeroBanner  
      ...ICmsMarketingDealsDonationBanner
      ...IAdIconsSection
      ...ICmsAdSection
      ...ICardCollection
      ...IDecoration
      ...ITextBlock  
      ...ICmsCardSwimlane
      ...ICmsSuperHotDealsBanner
      ...ICmsSuperHotDeals
      ...ICmsDailyDealsConfig
      ...ICmsMarketingBanner
      ...ICmsBannerImageSlot
      ...ImageCarousel
      ...ImageButtons
    }
  }
}`;

export const getDealsAdPage = gql`
  ${cmsAdSectionFragment}
  ${cmsCardSwimlaneFragment}
  ${superHotDealsBannerFragment}

  query GetDealsAdPage(
    $storeId: Int!,
    $fuelSaverCardNumber: String,
    $locationIds: [ID!]!
    $perksPricingEnabled: Boolean = false
    ) {
    allCmsMarketingDealsAdPage(
      limit: 1
      sort: { publishedAt: DESC }
      where:{
          _: {is_draft:false}
      }){
      _type
      _key
      dynamicGrid {
        _id
        _rev
        _createdAt
        desktopCards  
        tabletCards
        mobileCards 
        firstComponent {
          ...ICmsAdSection
          ...ICmsCardSwimlane
          ...ICmsSuperHotDealsBanner
        }
        secondComponent {
          ...ICmsAdSection
          ...ICmsCardSwimlane
          ...ICmsSuperHotDealsBanner
        } 
      }
    }
  } 
`;

export const getCmsWeeklyAdsPage = gql`
  ${citrusBannerImageSlotFragment}

  query WeeklyAdsPage {
    CmsWeeklyAdsPage: allCmsDealsWeeklyAdPage(
      limit: 1
      sort: { publishedAt: DESC }
      where:{
        _: {is_draft:false}
      }
    ) {
      _type
      bannerImageSlot {
        ...ICmsBannerImageSlot
      }
    }
  }
`;

export const getDealsShopPage = gql`
  query getDealsShopPage {
    allCmsDealsShipToHomePage(
            limit: 1
            sort: { publishedAt: DESC }
            where:{
                _: {is_draft:false}
            }) 
    {
        body {
          _key
          _type
          subtitle
          title
          icon {
            asset {
              url
            }
          }
          seeAllLink
          backgroundColor {
            hex
          }
          cardType {
            ... on CmsShopByCategory {
              shopCategory
            }
          }
    }
  }
}
`;

export const getCashBackFaqPage = gql`
${cmsTextBlock}
query CmsCashBackOffersFAQPage {
 allCmsCashBackOffersFAQPage(
    limit: 1
    sort: { publishedAt: DESC }
    where:{
        _: {is_draft:false}
    })
 {
    name
    body {
      ...ITextBlock
    }
 }
}`;

export const getTutorialSteps = gql`
    query getTutorialSteps {
        allCmsTutorialSteps (
            limit: 1
            sort: { publishedAt: DESC }
            where:{
                _: {is_draft:false}
            }) {
            steps {
                stepRaw
            }
        }
    }
`;

export const getSubNav = gql`
    query GetSubNav {
        allCmsSharedSubNavigationConfig(
            sort: { publishedAt: DESC },
            limit: 1,
            where: {
                site: {eq: "deals"},
                _: {is_draft: false}
            }
        ) {
            title
            site
            publishedAt
            links {
                text
                href
                locationType
            }
            overrideUrls
        }
    }
`;
